<template>
    <div class="kit-management__wrapper">
        <FilterSection class="filter-section" />
        <div class="f-align-center mb-20">
            <CRButton
                class="mr-10"
                pattern="section"
                :active="isRegularOrdersShown"
                @click="handleChangeOrderType(CheckOutTypeEnum.REGULAR_ORDER)"
            >
                {{ $t('buttons.regularOrders') }}
            </CRButton>
            <CRButton
                pattern="section"
                :active="isIndividualsOrdersShown"
                @click="handleChangeOrderType(CheckOutTypeEnum.INDIVIDUAL_ORDER)"
            >
                {{ $t('buttons.individualOrders') }}
            </CRButton>
        </div>
        <div v-if="!orders.length" class="empty__wrapper">
            <h1>{{ $t('orderManagement.noOrdersYet') }}</h1>
            <CRButton class="mt-25" @click="$router.push({ name: 'allMealKits' })">
                {{ $t('mealKits.title') }}
            </CRButton>
        </div>

        <div v-else class="card-item__wrapper">
            <component
                class="card"
                :is="cardComponent"
                v-for="order in orders"
                :key="order.id"
                :order="order"
                :checkOut="order.checkOut"
                :kit-order-settings="kitOrderSettings"
                @openIndividualOrderModal="openIndividualOrderModal"
                @updateIndividualOrder="updateIndividualOrder"
                @updateOrder="updateIndividualOrder"
                @reorderRegularKit="reorderRegularKit"
                @reorderIndividualOrder="reorderIndividualOrder"
                @openChangeOrderModal="openChangeOrderModal"
            />

            <ChangeOrderModal
                v-if="isChangeOrderModalOpen"
                :kit-order-settings="kitOrderSettings"
                :order="selectedOrder"
                :checkOut="selectedOrder.checkOut"
                @close="closeChangeOrderModal"
            />

            <IndividualOrderModal
                v-if="isIndividualOrderModalOpened"
                :checkOut="selectedOrder.checkOut"
                :order="selectedOrder"
                @close="isIndividualOrderModalOpened = false"
            />

            <ConfirmationModal
                v-if="isConfirmationOrderModalOpen"
                :title="confirmationOrderModalTitle"
                :description="confirmationOrderModalDescription"
                :is-loading="isLoading"
                @close="closeOrderConfirmationModal"
                @confirm="confirmActionFromConfirmationModal"
            />
        </div>
        <Pagination
            v-if="orders.length"
            :current-page="meta.currentPage"
            :page-count="meta.pageCount"
            :total-count="meta.totalCount"
            :per-page="meta.perPage"
            @pageNum="changePage"
        />
    </div>
</template>

<script>
    import ChangeOrderModal from '@/components/Modals/ChangeOrderModal.vue';
    import IndividualOrderKitCard from '@/components/Common/KitOrderCards/IndividualOrderKitCard.vue';
    import RegularOrderKitCard from '@/components/Common/KitOrderCards/RegularOrderKitCard.vue';
    import IndividualOrderModal from '@/components/Modals/IndividualOrderModal.vue';
    import ConfirmationModal from '@/components/Modals/ConfirmationModal.vue';
    import FilterSection from '@/components/KitManagement/FilterSection.vue';
    import Pagination from '@/components/Common/Pagination/Pagination.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { kitManagementModalMixin, reorderIOMixin, reorderROMixin } from '@/utils/mixins';
    import { mapActions, mapGetters, mapMutations } from 'vuex';
    import { CheckOutTypeEnum, OrderStatusEnum } from '@/utils/enums';
    import { IndividualOrderApi, OrderApi, KitOrderSettingApi } from '@/api';

    export default {
        name: 'KitManagement',
        components: {
            FilterSection,
            CRButton,
            Pagination,
            RegularOrderKitCard,
            IndividualOrderKitCard,
            IndividualOrderModal,
            ConfirmationModal,
            ChangeOrderModal,
        },
        mixins: [kitManagementModalMixin, reorderIOMixin, reorderROMixin],
        data() {
            return {
                CheckOutTypeEnum,

                isIndividualOrderModalOpened: false,
                selectedOrder: null,
                kitOrderSettings: null,

                isChangeOrderModalOpen: false,
            };
        },
        computed: {
            ...mapGetters('kitManagement', [
                'orders',
                'meta',
                'selectedOrderType',
                'isRegularOrdersShown',
                'isIndividualsOrdersShown',
            ]),

            cardComponent() {
                return this.isRegularOrdersShown ? RegularOrderKitCard : IndividualOrderKitCard;
            },
        },
        methods: {
            ...mapActions('kitManagement', ['getOrders', 'setOrderType']),
            ...mapMutations('kitManagement', ['setSelectedOrderType', 'updateOrderStatus']),

            openChangeOrderModal(order) {
                this.selectedOrder = order;
                this.isChangeOrderModalOpen = true;
            },

            async closeChangeOrderModal() {
                try {
                    this.selectedOrder = null;
                    this.isChangeOrderModalOpen = false;
                    await this.getOrders();
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            openIndividualOrderModal(order) {
                this.selectedOrder = order;
                this.isIndividualOrderModalOpened = true;
            },

            async handleChangeOrderType(type) {
                try {
                    await this.setOrderType(type);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async changePage(pageNum) {
                try {
                    await this.getOrders({ page: pageNum });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async updateIndividualOrder({ status, order }) {
                let amount = '';
                if (status === OrderStatusEnum.CANCELED_BY_USER || status === OrderStatusEnum.CANCELED_BY_ADMIN) {
                    const response = await OrderApi.getOrderCancellationPrice(order.id);
                    amount = response.amount;
                }

                this.selectedOrder = order;
                this.openOrderConfirmationModal(status, amount);
            },

            async confirmActionFromConfirmationModal() {
                this.isLoading = true;

                try {
                    const Api = this.isRegularOrdersShown ? OrderApi : IndividualOrderApi;

                    const response = await Api.update(this.selectedOrder.id, {
                        status: this.orderStatus,
                    });

                    this.updateOrderStatus({ id: this.selectedOrder.id, order: response.data });
                } catch (error) {
                    console.log(error);
                } finally {
                    this.closeOrderConfirmationModal();
                    this.isLoading = false;
                }
            },

            async fetchSettings() {
                try {
                    const response = await KitOrderSettingApi.get();
                    this.kitOrderSettings = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },
        async mounted() {
            await this.getOrders();
            await this.fetchSettings();
        },
    };
</script>

<style lang="scss" scoped>
    .kit-management__wrapper {
        padding: 25px 0;
    }

    .empty__wrapper {
        @include column-align-center-justify-center;

        min-height: 300px;
        width: 100%;
    }

    .card-item__wrapper {
        .card {
            width: 100%;

            margin-bottom: 20px;
        }
    }

    @include media($md) {
        .card-item__wrapper {
            @include row-align-start;
            flex-wrap: wrap;

            .card {
                width: 49%;
                margin-right: 2%;

                &:nth-of-type(2n) {
                    margin-right: 0;
                }
            }
        }
    }

    @include media($xl) {
        .card-item__wrapper {
            .card {
                width: 32%;

                &:nth-of-type(2n) {
                    margin-right: 2%;
                }

                &:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
        }
    }
</style>
