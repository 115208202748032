<template>
    <BasicModal class="basic-modal" @close="$emit('close')">
        <div class="container">
            <PreviewIndividualOrder
                class="preview-order"
                :order-state="order"
                :all-selected-additions-state="allSelectedAdditionsState"
                :delivery-data="deliveryData"
                :city-name="checkOut.deliveryAddress.city.name"
                :address-data="checkOut.deliveryAddress"
                :orderable-item="order.resource"
            />
        </div>
    </BasicModal>
</template>

<script>
    import BasicModal from '@/components/Modals/BasicModal.vue';
    import PreviewIndividualOrder from '@/components/Order/Individual/PreviewIndividualOrder.vue';

    export default {
        name: 'IndividualOrderModal',
        components: { BasicModal, PreviewIndividualOrder },
        props: {
            checkOut: {
                type: Object,
                default: () => {},
            },
            order: {
                type: Object,
                default: () => {},
            },
        },
        emits: ['close'],
        computed: {
            allSelectedAdditionsState() {
                return {
                    additions: this.order.additions,
                    allergens: this.order.allergens,
                    dislikedProducts: this.order.dislikedProducts,
                    dietPlans: this.order.dietPlans,
                };
            },

            deliveryData() {
                return {
                    deliveryStartDate: this.order.deliveryStartDate,
                    startTime: this.order.deliveryTime.startTime,
                    endTime: this.order.deliveryTime.endTime,
                };
            },
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        @include column-align-center-justify-center;

        width: 100%;

        padding: 20px;

        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        .preview-order {
            padding: 1px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
</style>
