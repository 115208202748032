<template>
    <div class="filter-section">
        <div class="filter-item">
            <h5>{{ $t('common.orderStatusFilter') }}</h5>
            <CRSelect class="select" :data="statusFilterData" :selected="params.status" @select="handelStatusSelect" />
        </div>
        <div class="filter-item">
            <h5>{{ $t('common.paymentFilter') }}</h5>
            <CRSelect
                class="select"
                :data="paymentTypeFilterData"
                :selected="params.paymentType"
                @select="handelPaymentTypeSelect"
            />
        </div>
        <div class="filter-item" :class="{ 'filter-item-lt': $i18n.locale === 'lt' }">
            <h5>{{ $t('common.sortBy') }}</h5>
            <CRSelect 
                class="select"
                :data="sortByFilterData"
                :selected="params.selectedSortBy"
                @select="handelSortBySelect"
            />
        </div>
    </div>
</template>

<script>
    import CRSelect from '@/components/Common/Select/CRSelect.vue';
    import {
        sortByFilterData,
        paymentTypeFilterData,
        regularOrderStatusFilterData,
        individualOrderStatusFilterData,
    } from '@/config/kitManagement/kitManagement';
    import { mapGetters, mapActions } from 'vuex';
    import { CheckOutTypeEnum } from '@/utils/enums';

    export default {
        name: 'FilterSection',
        components: { CRSelect },
        data() {
            return {
                sortByFilterData,
                paymentTypeFilterData,
            };
        },
        computed: {
            ...mapGetters('kitManagement', ['params', 'selectedOrderType']),

            statusFilterData() {
                return this.selectedOrderType === CheckOutTypeEnum.REGULAR_ORDER
                    ? regularOrderStatusFilterData
                    : individualOrderStatusFilterData;
            },
        },

        methods: {
            ...mapActions('kitManagement', ['getOrders']),

            async handelSortBySelect(item) {
                try {
                    await this.getOrders({
                        sortField: item.field,
                        sortDirection: item.fieldValue,
                        selectedSortBy: item.value,
                    });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async handelPaymentTypeSelect(item) {
                try {
                    await this.getOrders({ paymentType: item.value });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async handelStatusSelect(item) {
                try {
                    await this.getOrders({ status: item.value });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .filter-section {
        .filter-item {
            width: 100%;

            margin-bottom: 18px;

            h5 {
                margin-bottom: 8px;
            }

            .select {
                width: 100%;
            }

            &.filter-item-lt {
                width: 250px;
            }
        }
    }

    @include media($md) {
        .filter-section {
            @include row-align-end;

            flex-wrap: wrap;

            .filter-item {
                width: 220px;

                margin-bottom: 16px;
                margin-right: 32px;

                h5 {
                    margin-bottom: 8px;
                }

                .select {
                    width: 100%;
                }
            }

            .toggle-filter {
                @include row-align-center;

                margin-bottom: 20px;
            }
        }
    }

    @include media($lg) {
        .filter-section {
            @include row-align-end;

            flex-wrap: wrap;

            .filter-item {
                width: 220px;

                margin-bottom: 16px;
                margin-right: 32px;

                h5 {
                    margin-bottom: 8px;
                }

                .select {
                    width: 100%;
                }
            }
        }
    }
</style>
