<template>
    <BasicModal class="basic-modal" @close="$emit('close')">
        <div class="container" v-if="kitOrderSettings">
            <h2 class="mb-8">{{ $t('buttons.changeDetails') }}</h2>
            <h5>
                {{
                    $t('kitManagement.allChangesApartFromDeliveryWouldBeDoneAfter', {
                        hours: kitOrderSettings.changesAppliedTermHours,
                    })
                }}
            </h5>
            <div v-if="isRegularNotUniqueOrder">
                <h3 class="mb-16">
                    {{ $t('kitManagement.regularKitSelections') }}
                </h3>
                <div class="order-delivery-item">
                    <h4 class="mb-18">{{ $t('cart.kitType') }}</h4>
                    <CRRadio
                        class="radio"
                        v-for="menuType in order.mealKit.menuTypes"
                        v-model="form.menuTypeId"
                        :key="menuType.id"
                        :value="menuType.id"
                    >
                        {{ $t(`common.${menuType.type}`) }}
                    </CRRadio>
                </div>
                <div class="order-delivery-item">
                    <h4 class="mb-18">{{ $t('cart.mealSelection') }}</h4>
                    <CRRadio
                        class="radio"
                        v-for="combination in order.mealKit.combinations"
                        v-model="form.combinationId"
                        :key="combination.id"
                        :value="combination.id"
                    >
                        {{ $filters.getTranslationByKey(combination.name) }}
                    </CRRadio>
                </div>
            </div>
            <div v-if="isDeliverySectionVisible">
                <h3>{{ $t('common.delivery') }}</h3>
                <h5 class="mb-16">
                    {{ $t('kitManagement.ifChangeIsMadeToRegularKit') }}
                </h5>

                <div v-if="!isUniqueKitOrder" class="order-delivery-item">
                    <h4>{{ $t('common.deliveryDateSettings') }}</h4>
                    <p class="mb-20">
                        {{
                            $t('kitManagement.youCanChangeDeliveryDateBefore', {
                                hours: kitOrderSettings.deliveryDateChangesTermHours,
                            })
                        }}
                    </p>
                    <CRDatepicker
                        class="calendar"
                        inline
                        v-model="form.deliveryStartDate"
                        :allowed-dates="allowedDeliveryDates"
                        model-type="yyyy-MM-dd"
                        no-today
                    />
                </div>

                <div class="order-delivery-item">
                    <h4 class="mb-18">{{ $t('common.deliveryTime') }}</h4>
                    <CRRadio
                        class="radio"
                        v-for="time in displayedDeliveryTimes"
                        v-model="form.deliveryTimeId"
                        :key="time.id"
                        :value="time.id"
                    >
                        {{ getLabelForDeliveryTime(time) }}
                    </CRRadio>
                </div>
            </div>

            <CRButton @click="changeOrder" :disabled="isChangeButtonDisabled" :loading="isLoading">
                {{ $t('buttons.change') }}
            </CRButton>
        </div>
    </BasicModal>
</template>

<script>
    import BasicModal from '@/components/Modals/BasicModal.vue';
    import CRRadio from '@/components/Common/Radio/CRRadio.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRDatepicker from '@/components/Common/Datepicker/CRDatepicker.vue';
    import { CheckOutTypeEnum } from '@/utils/enums';
    import { CalendarDaysApi, DeliveryTimeApi } from '@/api';
    import { getLabelForDeliveryTime } from '@/helpers/DeliveryDateHelper';
    import { OrderApi, IndividualOrderApi } from '@/api';
    import dayjs from 'dayjs';

    export default {
        name: 'ChangeOrderModal',
        components: { BasicModal, CRRadio, CRDatepicker, CRButton },
        props: {
            checkOut: {
                type: Object,
                default: () => {},
            },
            order: {
                type: Object,
                default: () => {},
            },
            kitOrderSettings: {
                type: Object,
                default: () => {},
            },
        },
        emits: ['close'],
        data() {
            return {
                getLabelForDeliveryTime,
                allowedDeliveryDates: [],
                deliveryTimes: [],

                isDeliverySectionVisible: false,
                isLoading: false,
                form: {},
            };
        },
        computed: {
            isRegularNotUniqueOrder() {
                return this.checkOut.type === CheckOutTypeEnum.REGULAR_ORDER && !this.order.mealKit.isUnique;
            },

            isIndividualOrder() {
                return this.checkOut.type === CheckOutTypeEnum.INDIVIDUAL_ORDER;
            },

            isUniqueKitOrder() {
                return !(this.isRegularNotUniqueOrder || this.isIndividualOrder);
            },

            displayedDeliveryTimes() {
                return this.isUniqueKitOrder ? this.order.mealKit.deliveryTimes : this.deliveryTimes;
            },

            isChangeButtonDisabled() {
                if (this.isIndividualOrder) {
                    return (
                        this.form?.deliveryTimeId === this.order.deliveryTime.id &&
                        this.form?.deliveryStartDate === this.order.deliveryStartDate
                    );
                }

                const objectForComparing = this.order.updateRequest?.data ?? this.order;

                return (
                    this.form?.menuTypeId === objectForComparing.menuType?.id &&
                    this.form?.combinationId === objectForComparing.combination?.id &&
                    this.form?.deliveryTimeId === this.order.deliveryTime?.id &&
                    this.form?.deliveryStartDate === this.order.deliveryStartDate
                );
            },
        },
        methods: {
            async changeOrder() {
                this.isLoading = true;

                try {
                    let formCopy = { ...this.form };

                    if (!this.isDeliverySectionVisible) {
                        delete formCopy.deliveryTimeId;
                        delete formCopy.deliveryStartDate;
                    }

                    const data = {
                        changesData: formCopy,
                    };

                    const Api = this.isIndividualOrder ? IndividualOrderApi : OrderApi;
                    const response = await Api.update(this.order.id, data);
                    this.$emit('close', response.data);
                } catch (error) {
                    Object.entries(error.errors).forEach(async (element) => {
                        this.$filters.toast(element[1][0]);
                    });
                } finally {
                    this.isLoading = false;
                }
            },

            setInitialData() {
                const currentDate = dayjs().format('YYYY-MM-DD HH:mm');
                const availableDate = dayjs(this.order.deliveryStartDate + this.order.deliveryTime.startTime)
                    .subtract(this.kitOrderSettings.deliveryDateChangesTermHours, 'hours')
                    .format('YYYY-MM-DD HH:mm');

                this.isDeliverySectionVisible = currentDate < availableDate;

                if (this.isRegularNotUniqueOrder) {
                    this.form.menuTypeId = this.order.menuType.id;
                    this.form.combinationId = this.order.combination.id;
                }

                this.form.deliveryTimeId = this.order.deliveryTime.id;
                this.form.deliveryStartDate = this.order.deliveryStartDate;
            },

            async getCalendarDays() {
                try {
                    const withoutFirstThreeDays = this.checkOut.type === CheckOutTypeEnum.REGULAR_ORDER ? false : true;
                    const response = await CalendarDaysApi.getAvailableDays({ withoutFirstThreeDays });
                    this.allowedDeliveryDates = response.data.map((calendarDay) => calendarDay.date);
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async getDeliveryTimes() {
                try {
                    const response = await DeliveryTimeApi.getAll();
                    this.deliveryTimes = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },
        async mounted() {
            await this.getCalendarDays();
            await this.getDeliveryTimes();

            this.setInitialData();
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        @include column-align-start;

        width: 100%;

        padding: 20px;

        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        h5 {
            color: $grey-static;
            margin-bottom: 24px;
        }

        .order-delivery-item {
            margin-bottom: 24px;

            p {
                font-size: $font-14;
                font-style: italic;
            }

            .radio {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
</style>
