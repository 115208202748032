import { translation } from '@/helpers/TranslationHelper';
import { PaymentTypeEnum, OrderStatusEnum, IndividualOrderStatusEnum } from '@/utils/enums';

export const sortByFilterData = [
    { value: 1, label: translation('common.newestCreated'), field: 'created_at', fieldValue: 'desc' },
    { value: 2, label: translation('common.oldestCreated'), field: 'created_at', fieldValue: 'asc' },
];

export const paymentTypeFilterData = [
    { value: '', label: translation('common.none') },
    { value: PaymentTypeEnum.CASH, label: translation('common.cash') },
    { value: PaymentTypeEnum.MONTONIO, label: translation('common.montonio') },
    { value: PaymentTypeEnum.BANK_ACCOUNT, label: translation('common.bankTransfer') },
];

export const regularOrderStatusFilterData = [
    { value: '', label: translation('common.none') },
    {
        value: OrderStatusEnum.PENDING,
        label: translation('kitManagement.regularOrderStatuses.pending'),
    },
    {
        value: OrderStatusEnum.CANCELED_BY_ADMIN,
        label: translation('kitManagement.regularOrderStatuses.canceled_by_admin'),
    },
    {
        value: OrderStatusEnum.CANCELED_BY_USER,
        label: translation('kitManagement.regularOrderStatuses.canceled_by_user'),
    },
    {
        value: OrderStatusEnum.PAID,
        label: translation('kitManagement.regularOrderStatuses.paid'),
    },
    {
        value: OrderStatusEnum.NOT_PAID,
        label: translation('kitManagement.regularOrderStatuses.not_paid'),
    },
];

export const individualOrderStatusFilterData = [
    { value: '', label: translation('common.none') },
    {
        value: IndividualOrderStatusEnum.PENDING,
        label: translation('kitManagement.individualOrderStatuses.pending'),
    },
    {
        value: IndividualOrderStatusEnum.APPROVED,
        label: translation('kitManagement.individualOrderStatuses.approved'),
    },
    {
        value: IndividualOrderStatusEnum.CANCELLATION_REQUEST,
        label: translation('kitManagement.individualOrderStatuses.cancellation_request'),
    },
    {
        value: IndividualOrderStatusEnum.DECLINED,
        label: translation('kitManagement.individualOrderStatuses.declined'),
    },
    {
        value: IndividualOrderStatusEnum.PAID,
        label: translation('kitManagement.individualOrderStatuses.paid'),
    },
    {
        value: IndividualOrderStatusEnum.NOT_PAID,
        label: translation('kitManagement.individualOrderStatuses.not_paid'),
    },
];
